import { gql } from "@apollo/client";

export const GET_CURRENT_ADMIN = gql`
  query {
    currentAdmin {
      createdAt
      email
      fullname
      id
      phone
      phoneVerifiedAt
      suspendedAt
      suspendedReason
      updatedAt
    }
  }
`;
