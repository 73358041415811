import { MetroSpinner } from "react-spinners-kit";

const ContextLoader = (): JSX.Element => {
  return (
    <div className={"flex h-screen w-screen items-center justify-center"}>
      <MetroSpinner color={"#1A202C"} loading={true} size={35} />
    </div>
  );
};

export default ContextLoader;
