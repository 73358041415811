import { CircleSpinner } from "react-spinners-kit";
import { Fragment } from "react";

export interface LoaderComponent {
  color: string;
  size: number;
}

const ButtonLoader: React.FC<LoaderComponent> = ({
  size,
  color,
}): JSX.Element => {
  return (
    <Fragment>
      <div className={"flex justify-center flex-col items-center"}>
        <CircleSpinner color={color} loading={true} size={size} />
      </div>
    </Fragment>
  );
};

export default ButtonLoader;
