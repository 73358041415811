import React from 'react';
import { Toaster } from 'react-hot-toast';
import AppNavigator from './services/context';

const App = () => {
  return (
    <React.Fragment>
      <Toaster
        position={'bottom-right'}
        reverseOrder={false}
        toastOptions={{
          className: '',
          style: {
            background: '#1A202C',
            color: '#fff',
            zIndex: 1,
          },
          duration: 5000,
          // Default options for specific types
          success: {
            duration: 8000,
          },
          error: {
            style: {
              background: '#f44336',
            },
            duration: 8000,
          },
        }}
      />
      <AppNavigator />
    </React.Fragment>
  );
};

export default App;
