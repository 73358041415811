//auth
export const LOGIN = "/login";
export const FORGOTPASSWORD = "/forgotpassword";
export const VERIFY_RESET_CODE = "/resetpassword/verify";
export const CREATE_PASSWORD = "/resetpassword/create";

export const MAIN_LAYOUT = "/";

//for main pages
export const DASHBOARD = "/";
export const ADMIN_MANAGEMENT = "/admin-management";
export const SETTINGS = "/settings";
export const CONFIGURATION = "/configuration";
export const CLIENTS = "/clients";
export const RIDERS = "/riders";
export const BUSINESSES = "/businesses";
export const FUNDS = "/funds";

// for 404
export const NOT_FOUND = "/not-found";
