import * as React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { TopLoader } from "../shared/loaders";
import {
  LOGIN,
  MAIN_LAYOUT,
  NOT_FOUND,
  FORGOTPASSWORD,
  VERIFY_RESET_CODE,
  CREATE_PASSWORD,
} from "./constants";
import HandleNotFound from "./handle-not-found";
import CurrentAdmin from "../services/context/currentAdmin";

const LoginPage = React.lazy(() => import("../pages/authentication/login"));
const ForgotPassword = React.lazy(
  () => import("../pages/authentication/forgotpassword")
);
const VerifyResetCode = React.lazy(
  () => import("../pages/authentication/verify-reset-code")
);
const CreateResetPassword = React.lazy(
  () => import("../pages/authentication/create-password")
);

const Layout = React.lazy(() => import("../shared/layout"));

const RouterConfig: React.FC = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <React.Suspense fallback={TopLoader()}>
          <Switch>
            <Route exact={true} component={LoginPage} path={LOGIN} />
            <Route
              exact={true}
              component={ForgotPassword}
              path={FORGOTPASSWORD}
            />
            <Route
              exact={true}
              component={VerifyResetCode}
              path={VERIFY_RESET_CODE}
            />
            <Route
              exact={true}
              component={CreateResetPassword}
              path={CREATE_PASSWORD}
            />

            {/* handle 404s */}
            <Route exact={true} component={HandleNotFound} path={NOT_FOUND} />
            <CurrentAdmin>
              <Route component={Layout} path={MAIN_LAYOUT} />
            </CurrentAdmin>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default RouterConfig;
