import * as React from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { GET_CURRENT_ADMIN } from "../../services/graphql/auth/query";
import { DataLoader } from "../../shared/loaders";
import { CurrentAdmin } from "../../shared/types/misc";
import { Admin } from "../../shared/types/admins";
import toast from "react-hot-toast";

export const CurrentAdminContext = React.createContext<Admin | undefined>(
  {} as Admin
);

const CurrentClientComponent: React.FC = ({ children }) => {
  const { push } = useHistory();
  const { data, loading } = useQuery<CurrentAdmin, any>(GET_CURRENT_ADMIN, {
    onError: () => {
      toast.error("Unauthorized");
      push("/login");
    },
  });

  return (
    <React.Fragment>
      {loading ? (
        <React.Fragment>
          <div className="flex h-24 sm:h-24 md:h-72  flex-col items-center justify-center">
            <DataLoader />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CurrentAdminContext.Provider value={data?.currentAdmin}>
            {children}
          </CurrentAdminContext.Provider>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export const useCurrentAdmin = () => React.useContext(CurrentAdminContext);

export default CurrentClientComponent;
