import * as React from "react";
import { MetroSpinner } from "react-spinners-kit";

const DataLoader = () => {
  return (
    <React.Fragment>
      <div>
        <MetroSpinner color={"#1A202C"} size={50} loading={true} />
      </div>
    </React.Fragment>
  );
};

export default DataLoader;
