import { FC, useContext } from "react";
import { AuthContext } from "./context";
import Navigator from "./context";
import NavigationConfig from "../../navigation/router-config";

const AppNavigator: FC = () => {
  return (
    <>
      <Navigator>
        <NavigationConfig />
      </Navigator>
    </>
  );
};

export const useAuthProvider = () => useContext(AuthContext);

export default AppNavigator;
